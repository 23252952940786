import React from 'react';
import YourLuckyNumbersSection from '../../Sections/YourLuckyNumbersSection';
import LotteryInfo from '../../../templates/components/LotteryInfo';
import Results from '../../../templates/components/Results';

const YourLuckyNumbersPage = ({ buttonHandler, lotteriesNumbers }) => {

  const printGeneratedLotteries = () => {
    const result = [];

    const resultArrayCreation = (balls, bonusBall) => {
      const resultBallsArray = [];

      balls.map(ball => {
        resultBallsArray.push({
          value: ball
        });
      });

      bonusBall && resultBallsArray.push({
        value: bonusBall,
        type: 'BONUS'
      });

      return resultBallsArray;
    };

    lotteriesNumbers.map((lottery, index) => {
      result.push(
        <div key={index} className="lotteries-results__row">
          <LotteryInfo
            lotteryLogoSrc={lottery.img}
            lotteryName={lottery.name}
          />
          <Results results={resultArrayCreation(lottery.balls, lottery.bonusBall)} />
        </div>
      );
    });

    return result;
  };

  return (
    <YourLuckyNumbersSection>
      <div className="lotteries-results__header">
        <h5>Your lucky numbers</h5>
      </div>
      <main className="lotteries-results__wrapper lotteries-results__lucky-numbers">
        <div className="lotteries-results__container">
          {printGeneratedLotteries()}
        </div>
      </main>
      <button type="button" className="back-to-main-btn" onClick={buttonHandler}>
        Back To Main Page
      </button>
    </YourLuckyNumbersSection>
  );
};

export default YourLuckyNumbersPage;
